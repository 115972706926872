<template lang="pug">
div(v-if="emailList && emailList.length").w-100
  label(:class="`text-${color}`") {{ $t(label) }}
  div(v-for="(email, index) in emailList" :key="index").d-flex.align-center
    span {{ email.value }}
    v-btn(v-if="checkAccess" @click="$emit('deleteContact', email)" color="error" icon)
      v-icon mdi-trash-can-outline
</template>

<script>
export default {
  name: 'EmailList',
  props: {
    emailList: { type: Array, default: () => [] },
    label: { type: String, default: '' },
    color: { type: String, default: '' },
    checkAccess: { type: Boolean, default: false }
  }
}
</script>
